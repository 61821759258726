import * as React from "react"
import styled from "styled-components"
import { crownRing, grapeNecklace, cursus } from "../assets/image-urls"
import { Link } from "gatsby"

const Container = styled.div``
const GalleryContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  grid-gap: 40px 40px;
`

const Img = styled.img`
  object-fit: cover;
  width: 100%;
  object-position: 50% 50%;
  height: 400px;
  border-radius: 4px 4px 0 0;
`

const PhotoHolder = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  color: black;
  border: 1px solid #dadada;
  border-radius: 4px 4px 0 0;
  cursor: pointer;
  justify-content: space-between;
  box-shadow: 0px 8px 26px -10px rgba(0, 0, 0, 0.2);
  transition: 0.2s;

  &:hover {
    box-shadow: 0px 8px 26px -10px rgba(0, 0, 0, 0.5);
  }
`

const Title = styled.div`
  font-family: ${({ theme }) => theme.font.body};
  font-size: ${({ theme }) => theme.fontSize.xl}
  font-weight: ${({ theme }) => theme.fontWeight.bold}
  background: #ffffff;
  padding: 0.8rem 1.2rem;
  border-top: 1px solid #dadada;
  width: 100%;
  text-align: center;
`

const Gallery = () => {
  return (
    <Container>
      <GalleryContainer>
        <PhotoHolder to={"/our-atelier"}>
          <Img src={crownRing} />
          <Title>Over Atelier Stöpler</Title>
        </PhotoHolder>
        <PhotoHolder to={"/werkwijze"}>
          <Img src={grapeNecklace} />
          <Title>Werkwijze</Title>
        </PhotoHolder>
        <PhotoHolder to={"/cursus"}>
          <Img src={cursus} />
          <Title>Cursus</Title>
        </PhotoHolder>
      </GalleryContainer>
    </Container>
  )
}

export default Gallery
