import * as React from "react"
import styled from "styled-components"
import { heroRing } from "../assets/image-urls"
import { H1, P } from "../theme/elements"

const Container = styled.div`
  background: ${({ theme }) => theme.colors.white};
  height: 60vh;
`

const NameSection = styled.div`
  margin-top: 60px;
  min-height: 140px;
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 30px;
  row-gap: 4px;
`

const Img = styled.img`
  object-fit: cover;
  height: inherit;
  width: 100%;
  object-position: 50% 50%;
  box-shadow: 0 0 18px -1px rgba(0, 0, 0, 0.07);
`

export const Hero = () => (
  <div>
    <NameSection>
      <H1>Atelier Stöpler</H1>
      <P>Ambachtelijke sieraden met een persoonlijk verhaal.</P>
    </NameSection>
    <Container>
      <Img src={heroRing} alt={"Photo of gemstone"} />
    </Container>
  </div>
)

export default Hero
