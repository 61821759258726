import React, { useState } from "react"
import { Helmet } from "react-helmet"
import Hero from "../components/Hero"
import Navigation from "../components/Navigation"
import Gallery from "../components/Gallery"
import Footer from "../components/Footer"
import Section from "../components/Section"
import MenuScreen from "../components/MenuScreen"
import GlobalStyle from "../theme/global-style"
import { ThemeProvider } from "styled-components"
import Theme from "../theme/theme"
import SplitSection from "../components/SplitSection"

const pageContent = {
  title: "Atelier Stöpler — Op Maat Gemaakte Sieraden",
}

const IndexPage = () => {
  const [isOpen, setIsOpen] = useState(false)

  const openMenu = () => setIsOpen(true)
  const closeMenu = () => setIsOpen(false)

  return (
    <main>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{pageContent.title}</title>
        <link rel="canonical" href="https://atelierstopler.nl" />
      </Helmet>
      <ThemeProvider theme={Theme}>
        <GlobalStyle />
        <MenuScreen isOpen={isOpen} closeMenu={closeMenu} />
        {isOpen ? null : (
          <React.Fragment>
            <Navigation openMenu={openMenu} isOpen={isOpen} />
            <Hero />
            <Section>
              <Gallery />
            </Section>
            <SplitSection />
            <Footer />
          </React.Fragment>
        )}
      </ThemeProvider>
    </main>
  )
}

export default IndexPage
